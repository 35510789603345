/*
 * File: PartnerSelection.module.css
 * Project: autobuy-back-office
 * File Created: Tuesday, 22nd August 2023 10:19:49
 * Author: Simon (s.gourlet@autobuy.fr)
 * -----
 * Last Modified: Tuesday, 22nd August 2023 10:20:13
 * Modified By: Simon (s.gourlet@autobuy.fr>)
 * -----
 * Copyright 2022 - 2023 SAS Olivier Dubois, SAS Olivier Dubois
 */

.formHolder,
.formfield {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    width: 100%;
}

.formHolder {
    flex-direction: column;
}

@media (width < 700px) {
    .formfield{
        flex-direction: column;
    }
}
