/*
 * File: CarOffersSummary.module.css
 * Project: autobuy-back-office
 * File Created: Wednesday, 30th August 2023 10:46:49
 * Author: Simon (s.gourlet@autobuy.fr)
 * -----
 * Last Modified: Wednesday, 30th August 2023 03:33:54
 * Modified By: Simon (s.gourlet@autobuy.fr>)
 * -----
 * Copyright 2022 - 2023 SAS Olivier Dubois, SAS Olivier Dubois
 */

.offersDisplay {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
}

.offers {
    display: flex;
    flex-direction: column;
}

.topOffer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 1rem;
}

@media (width < 700px) {
    .topOffer {
        gap: 0rem;
        border-bottom: 1px black solid;
    }
    .value {
        text-align: center;
    }
    .topOffer > span {
        flex: 0 1 45%;
    }
    .offersDisplay {
        flex-direction: column;
    }
}
