/*
 * File: CarCard.module.css
 * Project: autobuy-back-office
 * File Created: Tuesday, 22nd August 2023 04:57:19
 * Author: Simon (s.gourlet@autobuy.fr)
 * -----
 * Last Modified: Friday, 19th January 2024 12:54:10
 * Modified By: Simon (s.gourlet@autobuy.fr>)
 * -----
 * Copyright 2022 - 2023 SAS Olivier Dubois, SAS Olivier Dubois
 */

.picture {
    object-fit: cover;
    width: 15rem;
    height: 100%;
}
.carCard {
    display: flex;
    width: 100%;
    background-color: white;
    cursor: pointer;
    box-shadow: 10px 5px 5px rgb(173, 173, 173);
}
.carCard:hover {
    background-color: rgba(128, 128, 128, 0.1);
}
.sellTd {
    width: 10rem;
    max-width: 10rem;
}
.carTitle {
    cursor: pointer;
    width: fit-content;
}

.pictureBox {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    width: 15rem;
    max-height: 9rem;
    overflow: hidden;
}
.carStatusHolder {
    position: absolute;
    background-color: whitesmoke;
    border-radius: 15px;
    padding: 1rem;
}
.sold,
.reserved {
    margin: 0;
}
.sold {
    color: red;
}
.reserved {
    color: royalblue;
}
.carBox {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0.5rem;
}

.carCardHolder {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.offersBest {
    display: flex;
    gap: 3rem;
    width: 100%;
}

.carInfo {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
}
.carInfo > * {
    flex: 1 1 40%;
    margin: 0;
}

.carBox:hover > .icons {
    display: block;
 }

.icons {
    position: absolute;
    display: none;
    right: -1rem;
    top: -1rem;
    z-index: 1;
}

.close {
    background-color: crimson;
    color: whitesmoke;
}

.import {
    color: blue;
}

.import,
.close {
    height: 1.5rem;
    width: 1.5rem;
    margin-left: 0.5rem;
    border-radius: 999px;
    opacity: 0.3;
}

.import:hover,
.close:hover {
    opacity: 1;
}

.offerNView {
    position: relative;
    bottom: 0rem;
    right: 0.5rem;
    align-self: flex-end;
}

.offerNView > p {
    margin: 0;
}
.carInfoHolder {
    display: flex;
}
.offersHolder {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

@media ( width < 500px ) {
    .carCard {
        flex-direction: column;
    }
    .carInfoHolder,
    .offersHolder {
        gap: 1rem;
        flex-direction: column;
    }
    .carInfo {
        width: 100%;
    }
}
