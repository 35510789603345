/*
 * File: OfferRow.module.css
 * Project: autobuy-back-office
 * File Created: Tuesday, 12th September 2023 06:53:20
 * Author: Simon (s.gourlet@autobuy.fr)
 * -----
 * Last Modified: Wednesday, 15th November 2023 03:08:42
 * Modified By: Simon (s.gourlet@autobuy.fr>)
 * -----
 * Copyright 2022 - 2023 SAS Olivier Dubois, SAS Olivier Dubois
 */

.row,
.cell {
    cursor: default;
}

.action {
    /* display: flex; */
    /* flex-direction: row; */
    /* flex-wrap: nowrap; */
    /* width: 100%;
    height: 100%; */
    color: lightblue;
    border: 0 solid white;
}

.giftedRow {
    border: solid 2px rgba(161, 211, 151, 1);
}

.input {
    min-width: 8rem;
}

.action:hover {
    color: blue
}

.action:disabled {
    color: grey
}

.wrapText {
    overflow-wrap: anywhere;
    display: inline;
}

.actionHolder{
    display: flex;
    align-items: center;
    justify-content: space-around;
    height: 100%;
}
