/*
 * File: Visuals.module.css
 * Project: autobuy-back-office
 * File Created: Friday, 4th August 2023 12:21:37
 * Author: Simon (s.gourlet@autobuy.fr)
 * -----
 * Last Modified: Friday, 4th August 2023 12:22:19
 * Modified By: Simon (s.gourlet@autobuy.fr>)
 * -----
 * Copyright 2022 - 2023 SAS Olivier Dubois, SAS Olivier Dubois
 */

 .picHolder {
    width: 100%;
    padding-left: 1rem;
    margin-bottom: 1rem;
}
