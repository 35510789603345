/*
 * File: AddOfferOnCar.module.css
 * Project: autobuy-back-office
 * File Created: Monday, 9th October 2023 01:28:49
 * Author: Simon (s.gourlet@autobuy.fr)
 * -----
 * Last Modified: Friday, 19th January 2024 02:23:04
 * Modified By: Simon (s.gourlet@autobuy.fr>)
 * -----
 * Copyright 2022 - 2023 SAS Olivier Dubois, SAS Olivier Dubois
 */

.carOffers {
    display: flex;
    gap: 1rem;
    flex-direction: row;
    flex-wrap: wrap;
    }

.carOfferInput {
    /* flex: 0 1 25%; */
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    align-items: center;

}

.carOfferButton {
    text-wrap: wrap;
    width: 10rem;
}

.carOfferButtonAlt {
    text-wrap: wrap;
    width: fit-content;
}

.carSpecialOfferButton {
    text-wrap: wrap;
    width: 5rem;

}

.carSpecialOfferButton > img {
    width: 100%;
    height: 100%;

}
