/*
 * File: CarrierTable.module.css
 * Project: autobuy-back-office
 * File Created: Wednesday, 27th September 2023 09:45:03
 * Author: Simon (s.gourlet@autobuy.fr)
 * -----
 * Last Modified: Friday, 31st May 2024 09:16:59
 * Modified By: Simon (s.gourlet@autobuy.fr>)
 * -----
 * Copyright 2022 - 2023 SAS Olivier Dubois, SAS Olivier Dubois
 */

.cell {
    text-align: center;
    z-index: 0 !important;
}

/* .cell:first-child {
    max-width: 0.5rem;
} */
