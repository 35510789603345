/*
 * File: PhoneInput.css
 * Project: autobuy-back-office
 * File Created: Thursday, 7th September 2023 09:45:49
 * Author: Simon (s.gourlet@autobuy.fr)
 * -----
 * Last Modified: Thursday, 7th September 2023 09:51:53
 * Modified By: Simon (s.gourlet@autobuy.fr>)
 * -----
 * Copyright 2022 - 2023 SAS Olivier Dubois, SAS Olivier Dubois
 */

.PhoneInputInput {
    border: var(--bs-border-width) solid var(--bs-border-color);
    color: var(--bs-body-color);
    border-radius: var(--bs-border-radius);
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    padding: 0.375rem 0.75rem;
}

.PhoneInputInput:focus {
    color: var(--bs-body-color);
    background-color: var(--bs-body-bg);
    border-color: #86b7fe;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(13,110,253,.25);
}
