/*
 * File: PasswordCreateInput.module.css
 * Project: autobuy-back-office
 * File Created: Thursday, 7th September 2023 11:55:21
 * Author: Simon (s.gourlet@autobuy.fr)
 * -----
 * Last Modified: Thursday, 7th September 2023 11:56:36
 * Modified By: Simon (s.gourlet@autobuy.fr>)
 * -----
 * Copyright 2022 - 2023 SAS Olivier Dubois, SAS Olivier Dubois
 */


.formHolder {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    width: 100%;
}

.formfield {
    width: 100%;
}
