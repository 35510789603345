/*
 * File: UserList.module.cs
 * Project: autobuy-back-office
 * File Created: Thursday, 31st August 2023 11:56:35
 * Author: Simon (s.gourlet@autobuy.fr)
 * -----
 * Last Modified: Thursday, 31st August 2023 06:47:08
 * Modified By: Simon (s.gourlet@autobuy.fr>)
 * -----
 * Copyright 2022 - 2023 SAS Olivier Dubois, SAS Olivier Dubois
 */

.columns {
    display: flex;
    float: left;
    position: relative;
    /* margin-right: 1rem; */
    /* column-count: 2;
    -webkit-column-count: 2;
    -moz-column-count: 2; */
}

.userTd {
    width: calc(100%/8);
    /* width: fit-content; */
}

.capitalizeText {
    text-transform: capitalize;
}

.action {
    min-width: 30rem;
    display: flex;
    gap: 1rem;
}
