/*
 * File: HomePage.module.css
 * Project: autobuy-back-office
 * File Created: Tuesday, 26th September 2023 01:44:53
 * Author: Simon (s.gourlet@autobuy.fr)
 * -----
 * Last Modified: Wednesday, 27th September 2023 09:39:11
 * Modified By: Simon (s.gourlet@autobuy.fr>)
 * -----
 * Copyright 2022 - 2023 SAS Olivier Dubois, SAS Olivier Dubois
 */

.tables {
    display: flex;
    margin-top: 2rem;
    flex-direction: column;
    width: 95%;
    align-self: center;
    justify-self: center;
}

.table {
    border: 3px solid;
    border-image-slice: 1;
    border-image-source: linear-gradient(to bottom, #005dc8, #59afff);
}

.loading {
    display: flex;
    justify-content:center;
    align-items: center;
    width: 100%;
    margin-bottom: 5rem;
}

.loadingStats {
    height: 12.5rem;
}

.loadingSell,
.loadingUser {
    height: 5.5rem;
}

.homePage {
    display: flex;
    flex-direction: column;
}

.boldText {
    font-weight: bolder;
}
